import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';

import defaultLogo from '../assets/logo/solcentre_logo.png';

export default createStore({
  state: {
    user: {
      id: null,
      token: null,
      email: null,
      role: null,
      firstName: null,
      lastName: null,
      logo: null,
      tc: null,
      exp: 0,
      subscriptionId: null,
      isInvoicePaid: null,
    },
    selectedProject: null,
    country: null,
    pageSetting: {
      alert: false,
      battery: false,
      financial: false,
      nem12: false,
      energyDemandName: 'Energy Demand',
      solarConsumedName: 'Solar Consumed',
      solarDeliveredName: 'Solar Delivered',
      solarExportedName: 'Solar Exported',
      stackConsumedExportedCurve: false,
      showBilling: false,
    },
    financial: {
      solarAccessFee: {
        inputTariff: null,
        exportTariff: null,
        solarConnectionFee: null,
      },
    },
    isFetching: false,
    isForbidden: false,
  },
  mutations: {
    setUser(
      state,
      {
        id,
        token,
        exp,
        email,
        role,
        firstName,
        lastName,
        logo,
        tc,
        subscriptionId,
        isInvoicePaid,
      },
    ) {
      state.user.id = id;
      state.user.token = token;
      state.user.email = email;
      state.user.exp = exp;
      state.user.role = role;
      state.user.firstName = firstName;
      state.user.lastName = lastName;
      state.user.logo = logo;
      state.user.tc = tc;
      state.user.subscriptionId = subscriptionId;
      state.user.isInvoicePaid = isInvoicePaid;
    },
    setTCRead(state) {
      state.user.tc = true;
    },
    setLogo(state, logo) {
      state.user.logo = logo;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setPageSetting(state, setting) {
      state.pageSetting = setting;
    },
    setSelectedProject(state, project) {
      state.selectedProject = project;
    },
    setSubscriptionId(state, subscriptionId) {
      state.user.subscriptionId = subscriptionId;
    },
    setSubscriptionInvoicePaid(state, isInvoicePaid) {
      state.user.isInvoicePaid = isInvoicePaid;
    },
    setSolarAccessFee(state, fee) {
      if (Array.isArray(fee)) {
        if (fee.length > 0) {
          state.financial.solarAccessFee = fee[0];
        } else if (fee.length === 0) {
          state.financial.solarAccessFee = {
            inputTariff: null,
            exportTariff: null,
            solarConnectionFee: null,
          };
        }
      }
    },
    setShowBilling(state, showBilling) {
      state.pageSetting.showBilling = showBilling;
    },
    setIsFetching(state, isFetching) {
      state.isFetching = isFetching;
    },
    setForbidden(state, isForbidden) {
      state.isForbidden = isForbidden;
    },
  },
  actions: {
    async logout({ commit }) {
      commit('setUser', {
        id: null,
        token: null,
        email: null,
        role: null,
        firstName: null,
        lastName: null,
        logo: null,
        tc: null,
        exp: 0,
        subscriptionId: null,
        isInvoicePaid: null,
      });
      commit('setShowBilling', false);
      await router.push('/login');
    },
  },
  getters: {
    isLoggedIn: (state) =>
      state.user.token && Date.now() / 1000 < state.user.exp,
    getLogo: (state) => state.user.logo || defaultLogo,
    isDisplayTabBar: (state) =>
      state.pageSetting.alert || state.pageSetting.battery,
    getRole: (state) => state.user.role?.toLowerCase() || '',
    getRegistrationLink: (state) =>
      `https://forms.zohopublic.com.au/allumeenergy/form/SolCentreRegistration2/formperma/sAY3l-2YoKUhSsYgOdvnW_Mh-V0cq4X45eLgsAQaqhM?email=${encodeURIComponent(
        state.user.email,
      )}&firstName=${encodeURIComponent(
        state.user.firstName,
      )}&lastName=${encodeURIComponent(state.user.lastName)}`,
  },
  modules: {},
  plugins: [createPersistedState()],
});
