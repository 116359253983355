<template>
  <div class="project">
    <div class="project__address" data-tn="site-address" v-if="!isFetching">
      <HomeOutlined /> {{ $store.state.selectedProject?.address ?? '' }}
    </div>

    <a-spin v-if="isFetching" class="project__spinner" />

    <a-tabs
      v-if="!isForbidden && !isFetching"
      :animated="false"
      class="project__tabs"
      centered
      v-model:activeKey="currentTab"
    >
      <a-tab-pane key="performance" class="project__tab-item">
        <template #tab>
          <span>
            <DashboardOutlined />
            Performance
          </span>
        </template>

        <PerformanceTabProvider />
      </a-tab-pane>
      <a-tab-pane
        v-if="$store.state.pageSetting?.alert ?? false"
        key="alert"
        class="project__tab-item"
      >
        <template #tab>
          <span>
            <BellOutlined />
            Alert
          </span>
        </template>
        <AlertTab />
      </a-tab-pane>
      <a-tab-pane
        v-if="$store.state.pageSetting?.battery ?? false"
        key="battery"
        class="project__tab-item"
      >
        <template #tab>
          <span>
            <ClockCircleOutlined />
            Battery Scheduler
          </span>
        </template>
        <BatteryTab />
      </a-tab-pane>
      <a-tab-pane key="site-configuration" class="project__tab-item">
        <template #tab>
          <span data-tn="site-configuration">
            <ToolOutlined />
            Site Configuration
          </span>
        </template>
        <SiteConfigurationTab />
      </a-tab-pane>
      <a-tab-pane
        v-if="$store.state.pageSetting?.financial ?? false"
        key="financial-analysis"
        class="project__tab-item"
      >
        <template #tab>
          <span>
            <FundViewOutlined />
            Financial Analysis
          </span>
        </template>
        <FinancialTab />
      </a-tab-pane>

      <a-tab-pane key="Access" class="project__tab-item">
        <template #tab>
          <span>
            <UnlockOutlined />
            Access Control
          </span>
        </template>

        <AccessTab @refreshData="fetchData" />
      </a-tab-pane>
    </a-tabs>

    <a-result
      v-if="isForbidden && !isFetching"
      status="403"
      title="403"
      sub-title="Sorry, you are not authorized to access this page."
    >
      <template #extra>
        <a-button type="primary" @click="$router.push('/')">Back Home</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
import {
  ClockCircleOutlined,
  BellOutlined,
  DashboardOutlined,
  ToolOutlined,
  FundViewOutlined,
  UnlockOutlined,
  HomeOutlined,
} from '@ant-design/icons-vue';
import PerformanceTabProvider from '../components/Tabs/PerformanceTabProvider';
import AlertTab from '../components/Tabs/AlertTab';
import AccessTab from '../components/Tabs/AccessTab';
import FinancialTab from '../components/Tabs/FinancialTab/FinancialTab';
import BatteryTab from '../components/Tabs/BatteryTab';
import SiteConfigurationTab from '../components/Tabs/SiteConfigurationTab/SiteConfigurationTab';
import http from '@/http/http';
import api from '@/http/api';
import { message } from 'ant-design-vue';

export default {
  name: 'Project',
  components: {
    ClockCircleOutlined,
    BellOutlined,
    DashboardOutlined,
    ToolOutlined,
    FundViewOutlined,
    PerformanceTabProvider,
    AlertTab,
    AccessTab,
    UnlockOutlined,
    FinancialTab,
    BatteryTab,
    SiteConfigurationTab,
    HomeOutlined,
  },

  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$store.commit('setForbidden', false);
      this.$store.commit('setIsFetching', true);

      try {
        const { data: project } = await http.get(
          api.getProjectDetails(this.$route.params.id),
        );
        this.$store.commit('setCountry', project.country);
        this.$store.commit('setPageSetting', project.pageSetting);
        this.$store.commit('setSelectedProject', project);

        this.$store.commit('setForbidden', false);
      } catch (e) {
        message.error(e);
      }
      this.$store.commit('setIsFetching', false);
    },
  },
  computed: {
    currentTab: {
      get() {
        return this.$route.params.tab;
      },
      set(tab) {
        this.$router.push(`/project/${this.$route.params.id}/${tab}`);
      },
    },
    isFetching() {
      return this.$store.state.isFetching;
    },
    isForbidden() {
      return this.$store.state.isForbidden;
    },
  },
};
</script>

<style lang="less" scoped>
.project {
  text-align: center;

  &__address {
    width: fit-content;
    margin: 0 auto 15px auto;
    border-bottom: 2px solid #bdbdbd;
  }

  &__tabs {
    text-align: center;
  }

  &__tab-item {
    text-align: left;
  }

  &__spinner {
    margin-top: 200px;
  }
}
</style>
