<template>
  <a-alert
    message="It looks like you don't have anything linked to your account"
    show-icon
    type="warning"
  />
  <div class="card-container">
    <Card title="Apartment Resident Users" class="next" no-footer>
      <div>
        <p>
          <b>Resident</b>: If you are a resident in a building with a SolShare
          system and wish to see your energy data, please click the button below
          to fill out your address details for us to validate and connect your
          account to your apartment.
        </p>
        <div style="text-align: center">
          <a-button
            type="primary"
            :href="registrationLink"
            target="_blank"
            class="large-button"
            data-tn="registration-link"
          >
            Verify your address
          </a-button>
        </div>
      </div>
    </Card>
    <Card title="Asset Owner / Installer Users" class="next" no-footer>
      <div>
        <p>
          If you are an asset owner of a solar system with SolShare, please
          request access from the solar installer of the system, or another
          Asset Owner who can add you.
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  computed: {
    registrationLink() {
      return this.$store.getters.getRegistrationLink;
    },
  },
};
</script>

<style lang="less" scoped>
.large-button {
  width: 200px;
}
.card-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.card-container .next {
  flex: 1;
  margin: 0 10px;
}
</style>
