<template>
  <div class="login">
    <Background />
    <div class="login__container">
      <img class="login__logo__circle" :src="circle" alt="logo" />
      <img class="login__logo" :src="logo" alt="logo" />
      <div class="login__form-container">
        <h2 class="login__title">Login or Register</h2>
        <div class="login-form">
          <a-form
            :model="loginForm"
            :rules="rules"
            :wrapper-col="{ span: 24 }"
            @finish="handleLogin"
          >
            <a-form-item name="email" validateFirst>
              <a-input
                v-model:value="loginForm.email"
                size="large"
                placeholder="Email"
                autocomplete="username"
                @blur="getLogo"
                data-tn="login-email-input"
              />
            </a-form-item>
            <a-form-item name="password">
              <a-input-password
                v-model:value="loginForm.password"
                size="large"
                placeholder="Password"
                autocomplete="current-password"
                data-tn="login-password-input"
              />
            </a-form-item>

            <div class="login__more-operation">
              <a @click="registerClicked"> Register </a>
              <a @click="forgetPasswordClicked"> forgot password? </a>
            </div>

            <a-button
              block
              size="large"
              type="primary"
              html-type="submit"
              :loading="isSubmitting"
              :style="{
                height: '47px',
                marginTop: '5px',
                borderRadius: '25px',
              }"
              data-tn="login-submit"
            >
              LOGIN
            </a-button>
          </a-form>
        </div>
      </div>
    </div>
    <Copyright />

    <RegisterModal v-model:visible="registerModalVisible" />
    <ForgetPasswordModal v-model:visible="forgetPasswordModalVisible" />
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';

import Background from '../components/Background.vue';
import Copyright from '../components/Copyright.vue';
import RegisterModal from '@/components/Modals/RegisterModal.vue';
import { validateEmailAddress } from '@/utils/validators';
import ForgetPasswordModal from '@/components/Modals/ForgetPasswordModal.vue';
import circle from '../assets/logo/favicon_circlelg.png';

export default {
  name: 'Home',
  components: { ForgetPasswordModal, RegisterModal, Background, Copyright },
  data() {
    return {
      circle,
      isSubmitting: false,
      loginForm: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          {
            validator: validateEmailAddress,
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
        ],
      },
      forgetPasswordModalVisible: false,
      registerModalVisible: false,
    };
  },
  computed: {
    logo() {
      return this.$store.getters.getLogo;
    },
  },
  methods: {
    registerClicked() {
      this.registerModalVisible = true;
    },
    forgetPasswordClicked() {
      this.forgetPasswordModalVisible = true;
    },
    async handleLogin() {
      this.isSubmitting = true;

      try {
        const {
          data: { accessToken, user },
        } = await this.$http.post(this.$api.login, this.loginForm);
        const { exp, email, role } = jwt_decode(accessToken);
        this.$store.commit('setUser', {
          id: user.id,
          token: accessToken,
          exp,
          email,
          role,
          firstName: user.firstName,
          lastName: user.lastName,
          logo: user.logo,
          tc: user.tcAccepted,
          subscriptionId: user.subscriptionId,
          isInvoicePaid: user.isInvoicePaid,
        });
        const redirectPath = localStorage.getItem('redirectPath');
        if (redirectPath) {
          await this.$router.push(redirectPath);
          localStorage.removeItem('redirectPath');
        } else {
          await this.$router.push('/');
        }
        document.title =
          role === 'Provider'
            ? 'Provider Portal | Allume Energy'
            : 'Consumer Portal | Allume Energy';
      } catch (e) {
        this.$message.error(e);
      }
      this.isSubmitting = false;
    },
    async getLogo() {
      if (this.loginForm.email === '') {
        return;
      }
      try {
        const { data } = await this.$http.get(
          this.$api.getLogo(this.loginForm.email),
        );
        if (data) {
          this.$store.commit('setLogo', data);
        } else {
          this.$store.commit('setLogo', undefined);
        }
      } catch (ignored) {}
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    margin: 0 auto;
    padding-top: 100px;
    width: 390px;
    position: relative;
    z-index: 1;
  }

  &__logo__circle {
    height: 80px;
    display: block;
    margin: 0 auto 30px;
  }

  &__logo {
    display: block;
    margin: 0 auto 50px;
    width: 390px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
    color: #021e1b;
  }

  &__form-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.07);
    padding: 60px 50px;
  }

  &__more-operation {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 5px;

    a {
      color: #08645a;
    }
  }

  &__label-btn {
    display: block;
    text-align: center;
    margin-top: 13px;
    z-index: 0;
  }

  &__login-tip {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 45px;
  }
}
</style>
